<template>
    <swiper
    :slides-per-view="1"
    :space-between="50"
    @swiper="onSwiper"
    @slideChange="onSlideChange">
    <swiper-slide v-for="(item, index) in sliders" :key="index">
        <img :src="item.link" alt="">
        <div class="tag" v-if="index = 1"><i class="fa fa-tag" /><span>IU</span></div>
    </swiper-slide>
  </swiper>
</template>
<script>
import {Swiper, SwiperSlide} from 'swiper/vue'

import 'swiper/swiper.scss'

export default {
    components: {
        Swiper,
        SwiperSlide
    },
    methods: {
        onSwiper(swiper) {
            console.log(swiper)
        },
        onSlideChange() {
            console.log('slide change')
        }
    },
    props: ["sliders"],
    data() {
        return {
            headerHeight: document.getElementById('header').offsetHeight,
        }
    }
}
</script>
<style lang="scss">
    .swiper-container {
        height: calc(100vh - v-bind(headerHeight));

        .swiper-wrapper {
            @include flex(normal, center);

            .swiper-slide {
                .tag {
                    position: absolute;
                    top: 300px;
                    left: 300px;
                    color: #fff;
                    cursor: pointer;
                    border: .2px solid #ddd;
                    padding: 10px;

                    .fa {
                        color: #ddd;
                    }

                    span {
                        margin-left: 10px;
                    }
                }
            }
        }
        
        img {
            max-width: 100%;
            max-height: 100%;
            width: 100%;
        }
    }
</style>